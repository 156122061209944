<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showEditButton
    showCreateButton
    showDeleteButton
    @create="onCreate('DomaAiIntegrationNew')"
    @edit="(id) => onEdit('DomaAiIntegrationEdit', id)"
  />
</template>

<script>
import editableList from '@/mixins/editableList.js';

import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'DomaAiIntegration',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'DomaAiIntegration',
      tableCaption: 'Интеграция с "Дома АИ"',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Интеграция с "Дома АИ"',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'ИНН', alias: 'inn', order: 'inn' },
        { text: 'База данных', alias: 'databaseName', order: 'databaseName' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
